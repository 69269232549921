<script setup lang="ts">
</script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.33 6.76">
    <polyline points="3.09 6.26 .5 3.38 3.09 .5"
              style="fill:none; stroke:currentColor; stroke-linecap:round; stroke-linejoin:round;" />
    <line x1=".5" y1="3.38" x2="8.83" y2="3.38"
          style="fill:none; stroke:currentColor; stroke-linecap:round; stroke-linejoin:round;" />
  </svg>

</template>

<style scoped></style>
